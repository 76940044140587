<template>
  <div id="app">
    <TransverseHead></TransverseHead>
    <router-view></router-view>
  </div>
</template>

<script>
import TransverseHead from '@/components/TransverseHead.vue'

export default {
  // data:{
  //   img:'123'
  // },
  components: {
    TransverseHead
  }
}

</script>
<style >
#app {
  height: 650px;
  width: 100%;
  margin: 0;
  padding: 0;
}
*{
  margin: 0;
  padding: 0;
  /* font-size: 13px; */
}
ul li{
  list-style: none;
}
body{
  background-color: rgb(238, 238, 238 );
}
.box{
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  .green{
    color: green;
    /* font-weight: 700; */
  }
  .greenbtn{
    background-color: rgb(51, 171, 160)!important;
    color: white !important;;
  }
  .ccc{
    color: #999;
  }
  .required-field::before {  
  content: "*"; /* 伪元素要显示的内容 */  
  color: red !important; /* 符号的颜色 */  
  font-weight: bold; /* 符号字体粗细 */  
  margin-right: 4px; /* 符号与标签文本之间的间距 */  
}
.Description{
    /* height: 50px; */
    line-height: 24px;
    padding: 15px 20px;
    border-left: 4px solid rgb(0, 150, 136);
    background-color: rgb(242, 242, 242);
    /* padding-left: 20px; */
}
</style>
