import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import plugins from './plugins'
//导入组件库
import ElementUI from 'element-ui'

//导入组件库相关样式
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$imgUrl = 'http://192.168.2.10:8080/api/upload/imagesss';
const userStr = sessionStorage.getItem('id');
if (userStr) {
  store.commit('SET_LOGGED_IN', true);
}
Vue.use(plugins)
new Vue({
  // data:{
  //   imgUrl:'123'
  // },
  router,
  store,
  render: h => h(App),

}).$mount('#app')

