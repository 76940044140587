<template>
  <div class="transverseHead" >
    <el-menu
          style="position: fixed ; z-index: 3 ; width: 100%"
        :default-active="activeIndex"
        class="custom-menu"
        mode="horizontal"
        router="router"
        @select="handleSelect"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b">
      <div class="menu-left">
        <el-menu-item index="1"style="">TFEX</el-menu-item>
        <el-menu-item index="2">
          <el-header>
            <!-- 点击展开收起导航和切换对应图标 -->
            <i class="el-icon-s-fold" @click="isC" v-show="block"></i>
            <i class="el-icon-s-unfold" @click="isC" v-show="toggle"></i>
          </el-header>
        </el-menu-item>
        <el-menu-item index="/home">后台首页</el-menu-item>
        <el-menu-item index="/product">产品管理</el-menu-item>
        <el-menu-item index="/user">用户中心</el-menu-item>
        <el-menu-item index="/info">信息管理</el-menu-item>
        <el-menu-item index="/channel">系统管理</el-menu-item>
      </div>
      <div class="menu-center">
        <el-menu-item index="/user/topUpRecord">
          充值
          <el-button type="warning" class="mini-button">0</el-button>
        </el-menu-item>
        <el-menu-item index="/user/withdrawalRecord">
          提现
          <el-button type="warning" class="mini-button">0</el-button>
        </el-menu-item>
        <el-menu-item index="/product/transactionFlow">
          订单
          <el-button type="warning" class="mini-button">0</el-button>
        </el-menu-item>
        <el-menu-item index="11"><i class="el-icon-refresh-right"></i></el-menu-item>
      </div>
      <div class="menu-right">
        <el-submenu index="13">
          <template slot="title">admin</template>
          <el-menu-item index="/error505">基本资料</el-menu-item>
          <el-menu-item index="/error505">安全设置</el-menu-item>
          <el-menu-item index="/error505">压缩发布</el-menu-item>
          <el-menu-item index="/error505">清理缓存</el-menu-item>
          <el-menu-item index="/login" @click="logout">退出登录</el-menu-item>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>

<script>
import "@/style/miniButton.css"

export default {
  data() {
    return {
      isCollapse: false, //导航栏默认为展开
      toggle: false,//第二个图标默认隐藏
      block: true,//默认显示第一个图标
      activeIndex: '/home',
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      // this.activeIndex = key;
    },
    logout(){
      this.$store.commit('SET_LOGGED_IN', false)
    },
    isC() {
      this.isCollapse = !this.isCollapse;
      this.toggle = !this.toggle;
      this.block = !this.block;
    },
  }
}
</script>

<style scoped>
.custom-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-menu{
  border: 0 !important;

}
.menu-left {
  width: 65%;
  display: flex;
  align-items: center;
}

.menu-center {
  width: 25%;
  display: flex;
  align-items: center;
}

.menu-right {
  width: 10%;
}

</style>